:root{
    --orange: #ec720b;
    --green: #24873f;
    --main-primary: #a6c000;
}
#notyf-message{
    display: none;
}
.text-orange {
    color: var(--orange);
}
.text-green {
    color: var(--green);
}
.bg-orange {
    background: var(--orange) !important;
    color: white;
}
.bg-green {
    background: var(--green) !important;
    color: white;
}
.text-main-primary{
    color:var(--main-primary);
}
.bg-main-primary {
    background: var(--main-primary) !important;
    color:white;
}
.cursor-pointer {
    cursor: pointer;
}
#customer_plainPassword{
    height: 3.8rem;
}

.aside-menu .menu-item .menu-link.active {
    transition: color .2s ease;
    background-color: var(--main-primary) !important;
    color: #fff;
}
.aside,.header-fixed .header  {
    background-color:var(--green) !important;

}
.aside-menu .menu-item .menu-link .menu-title, .menu-title>a{
    color: #fff !important;
}
.aside-menu .menu-item .menu-link .menu-bullet .bullet {
    background-color: #fff !important;
}
.aside .aside-logo {
    background-color: transparent !important;
}
.aside-menu .menu-item .menu-link .menu-arrow::after {
    background-color: #fff !important;
}